@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}

@mixin noHighlight() {
  -webkit-tap-highlight-color: transparent;
}

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin spaceBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin visuallyHidden() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin fullpageBG {
  position: relative;
  background: none;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: calc((100vw - 100%) / 2 * -1);
    width: 100vw;
    top: 0;
    bottom: 0;
    @content;
  }
}

@mixin objectFitCover() {
  object-fit: cover;
  object-position: center;
}

@mixin objectFitCoverTop() {
  object-fit: cover;
  object-position: center top;
}
@mixin objectFitCoverBottom() {
  object-fit: cover;
  object-position: center bottom;
}

@mixin objectFitContain() {
  object-fit: contain;
  object-position: center;
}

@mixin objectFitContainBottom() {
  object-fit: contain;
  object-position: center bottom;
}

@mixin objectFitContainTop() {
  object-fit: contain;
  object-position: center top;
}

@mixin noScrollbar {
  &::-webkit-scrollbar * {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    display: none;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

@mixin customScrollbar() {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    background-color: var(--white);
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--brand-primary);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
}

@mixin textEllipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin mobileHorizontalScroll {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  margin-left: calc(-1 * var(--content-padding));
  margin-right: calc(-1 * var(--content-padding));
  position: relative;
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
  white-space: nowrap;

  & > * {
    flex-shrink: 0;
  }

  &::after {
    content: '';
    width: var(--content-padding);
    flex-shrink: 0;
    height: 1px;
  }
}

@mixin centeredAbsolute {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

@mixin centeredFixed {
  left: 50%;
  top: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}

@mixin fullAbsolute {
  @include mobileContainerWidth();
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

@mixin hiddenByDefault {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s linear 0.3s;

  &.active {
    opacity: 1;
    visibility: inherit;
    transition: opacity 0.3s, visibility 0.3s;
  }
}

@mixin mobileContainerWidth() {
  width: var(--mobile-container-width);

  @include breakpoint(mobile) {
    width: 100%;
  }
}

@mixin breakpoint($class) {
  @if $class == large-desktop {
    @media only screen and (max-width: (1600px)) {
      @content;
    }
  } @else if $class == desktop {
    @media only screen and (max-width: (1400px)) {
      @content;
    }
  } @else if $class == laptop {
    @media only screen and (max-width: (1200px)) {
      @content;
    }
  } @else if $class == tablet {
    @media only screen and (max-width: (1024px)) {
      @content;
    }
  } @else if $class == small-tablet {
    @media only screen and (max-width: (768px)) {
      @content;
    }
  } @else if $class == mobile {
    @media only screen and (max-width: (640px)) {
      @content;
    }
  } @else if $class == small-mobile {
    @media only screen and (max-width: (576px)) {
      @content;
    }
  } @else if $class == supersmall-mobile {
    @media only screen and (max-width: (370px)) {
      @content;
    }
  }
}

@mixin breakpointMF($class) {
  @if $class == large-desktop {
    @media only screen and (min-width: 1401px) {
      @content;
    }
  } @else if $class == desktop {
    @media only screen and (min-width: 1201px) {
      @content;
    }
  } @else if $class == laptop {
    @media only screen and (min-width: 1025px) {
      @content;
    }
  } @else if $class == tablet {
    @media only screen and (min-width: 769px) {
      @content;
    }
  } @else if $class == small-tablet {
    @media only screen and (min-width: 641px) {
      @content;
    }
  } @else if $class == mobile {
    @media only screen and (min-width: 577px) {
      @content;
    }
  }
}

@mixin word-wrap () {
  word-break: break-word;
  hyphens: auto;
  /* Non standard for WebKit */
}

@mixin button-wrapper {
  padding: 12px 16px;
}
