@import '../../../scss/mixins';

.container {
  background: rgba(0, 0, 0, 0.35);
  height: 100%;
  position: fixed;
  display: flex;
  align-items: flex-end;
  z-index: 15;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  pointer-events: auto;
  cursor: pointer;

  padding-bottom: env(safe-area-inset-bottom);

  .sheet {
    // max-height: 600px;
    @include mobileContainerWidth();
    margin: 0 auto;
    background: var(--background-overlay);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(15, 0, 30, 0.15);
    border-radius: 12px 12px 0px 0px;
    position: relative;
    transition: height 0.5s;

    .handle {
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        width: 36px;
        height: 4px;
        top: 8px;

        background: var(--gray30);
        border-radius: 2px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 16px 0 0;
      align-items: center;
      text-align: center;
    }
  }

  .visible {
    height: auto;
  }

  .hidden {
    height: 0;
  }
}
